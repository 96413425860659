<template>
  <div class="d-flex fill-height" fliud>
    <v-app-bar app>
      <div
        v-if="$vuetify.breakpoint.xs"
        class="appButtonContainer d-flex align-center justify-center"
      >
        <v-icon @click="$router.push('/bridge')"> mdi-arrow-left</v-icon>
      </div>
      <v-app-bar-title v-if="bridgeDetail.info.name">
        <template v-slot:default>
          <div class="ml-4" style="width: 400px">
            <div class="text-color font-weight-bold">
              {{ header }}
            </div>
            <div class="text-caption">{{ subHeader }}</div>
          </div>
        </template>
      </v-app-bar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :color="'#E5E5E5'"
      :permanent="$vuetify.breakpoint.smAndUp"
      width="80"
      disable-resize-watcher
      touchless
      app
    >
      <v-list v-if="$vuetify.breakpoint.smAndUp" color="white">
        <v-list-item
          class="d-flex justify-center"
          @click="$router.push('/bridge')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-list-item>
      </v-list>
      <v-list class="pa-0" dense>
        <v-list-item-group active-class="background-active">
          <v-list-item
            v-for="item in items"
            :key="item.path"
            :to="{ path: item.path, query: { id: $route.query.id } }"
            exact-path
            class="pa-0"
            v-show="item.name !== '3d' || item.pointCloud"
          >
            <v-list-item-content class="mx-0 px-0">
              <v-icon>{{ item.icon }}</v-icon>
              <v-list-item-title
                class="text-caption text-center font-weight-regular"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-container class="pa-0 ma-0" fluid>
      <router-view v-if="!isloading" class="fill-height pt-0"> </router-view>
      <v-row v-else align="center" class="fill-height ma-0" justify="center">
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-container>
    <v-btn
      v-if="$vuetify.breakpoint.xs"
      class="primary rounded-l-0 mt-3"
      fixed
      @click="drawer = !drawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    items: {
      inventory: {
        title: 'Inventory',
        name: 'detail',
        icon: 'mdi-information-outline',
        path: '/inventory'
      },
      // {
      //   title: 'Visualization',
      //   name: 'visualization',
      //   icon: 'mdi-bridge',
      //   path: '/inventory/visualization'
      // },
      forecast: {
        title: 'Forecast',
        name: 'forecast',
        icon: 'mdi-content-paste',
        path: '/inventory/forecast'
      },
      threeD: {
        title: '3D Model',
        name: '3d',
        icon: 'mdi-printer-3d',
        path: '/inventory/3d',
        pointCloud: false
      }
    },
    bridgeDetail: {
      id: '',
      info: {}
    },
    isloading: true,
    drawer: false
  }),
  computed: {
    ...mapState('appbarText', ['header', 'subHeader'])
  },
  async mounted () {
    this.isLoaded = true
    await this.getBridgeDetail()
    await this.getPointCloudMetaData()
    this.isloading = false
  },
  methods: {
    async getBridgeDetail () {
      this.isloading = true
      this.$store.dispatch('bridgeDetail/resetState')
      this.bridgeDetail = await this.$store.dispatch(
        'bridgeDetail/getBridgeDetail',
        {
          id: this.$route.query.id
        }
      )
      this.setAppbar()
      await this.getBridgeOverview()
    },
    async getBridgeOverview () {
      const payload = { bridgeCode: this.$route.query.id }
      await this.$store.dispatch('getBridgeOverview', payload)
    },
    setAppbar () {
      const header = this.getHeaderName(this.bridgeDetail.info)
      this.$store.commit('appbarText/SET_HEADER', header)
      const subHeader = `ทล. ${parseInt(
        this.bridgeDetail.info.road_code
      )} กม. ${this.getKm(this.bridgeDetail.info.km || 0)}`
      this.$store.commit('appbarText/SET_SUB_HEADER', subHeader)
    },
    getKm (km) {
      const numKm = Math.floor(km / 1000).toString()
      return `${numKm}+${km
        .toString()
        .substring(numKm.length)
        .padStart(3, '0')}`
    },
    getHeaderName (info) {
      return `${info.name} ${info.direction ? `(${info.direction})` : ''}`
    },
    async getPointCloudMetaData () {
      const pointCloud = await this.$store.dispatch('getPointCloudMetaData', {
        id: this.$route.query.id
      })
      if (pointCloud) {
        this.items.threeD.pointCloud = true
        this.isloading = false
      } else {
        this.items.threeD.pointCloud = false
        if (this.$route.name === '3d') {
          this.$router.push({
            name: 'detail',
            query: { id: this.$route.query.id }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content,
.v-toolbar__extension {
  padding: 0;
}
.background-active {
  background: #c22d47;
  color: white;
}
.text-color {
  color: #c22d47;
}
.appButtonContainer {
  width: 64px;
  height: 100%;
}
</style>
